.all-maps-container {
  /* background-color: var(--color0); */
  /* border: 1px solid var(--color0); */
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.all-maps-select {
  /* background-color: yellow; */
  margin: auto;
  padding: 10px;
  margin-bottom: 10px;
}

.all-maps-text {
  /* text-align: center; */
  /* margin: 10px 20px 0px 20px; */
  padding: 5px;
  color: var(--color0);
  font-weight: 900;
  display: flex;
  /* justify-content: space-evenly; */
}

.-step3 {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  padding-left: 0px;
  
}

.all-maps-button {
  margin: 0px 20px 0px 20px;
  color: var(--color0);
  border: 1px solid var(--color0);
padding: 1px 5px 1px 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.all-maps-button:hover {
  background-color: var(--color0);
  color: var(--color1);
}

.maps-delete-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 426px)  {
  .-step3 {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 426px) {
  .all-maps-select {
    max-width: 250px;
  }

  .all-maps-container {
    margin: 5px;
    padding: 10px 2px 10px 2px;
  }


  .all-maps-button {
    margin-bottom: 5px;
    padding: 5px;
    /* max-width: 100px; */
  }
}
