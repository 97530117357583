@font-face {
  font-family: "Rams W01 Black", Arial, sans-serif;
  src: url('../fonts/Rams\ W01\ Black.woff');
}



.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background-color: var(--color1); */
    font-family: 'Rams W01 Black', sans-serif;
}

.welcome-question {
  font-size: 24px;
  margin-bottom: 50px;
  color: var(--color0); 
  font-weight: 700;
}

.welcome-buttons {
  display: flex;
  align-items: center;
  
}

.welcome-button-one,
.welcome-button-two {
  font-family: 'Rams W01 Black', sans-serif;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.welcome-button-one {
  background-color: var(--color0); 
  color: var(--color1); 
}

.welcome-button-two {
  background-color: var(--color0);
  color: var(--color1); 
}

.welcome-button-one:hover {
  background-color: var(--color4);
  color: var(--color0); 
}

.welcome-button-two:hover {
  background-color: var(--color4); 
  color: var(--color0); 
}

.welcome-or {
  margin: 0 10px;
  font-size: 18px;
  color: var(--color0); 
}

@media (max-width: 400px) {
  .welcome-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .welcome-button-one,
.welcome-button-two {
margin: 20px 0px 20px 0px;
padding: 30px;
}
  

  }
  
