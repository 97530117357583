/* || RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@font-face {
  font-family: "Rams W01 Black", Arial, sans-serif;
  src: url('../src/fonts/Rams\ W01\ Black.woff');
}

.app-and-head {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  position: relative;
}

.head {
  background-color: white;
  z-index: 1001;
  position: fixed;
  width: 100%;
  top: 0;
  height: 75px;
}

.app {
  padding-top: 75px;
  flex-grow: 1;
  overflow-y: auto;
}

body.footer-visible {
  padding-bottom: 60px;
}

body.footer-visible .footer {
  display: block;
}

/* COLORS */

:root {
  --color-black: black;
  --color-white: white;
  --color0: #fc4c02;
  --color1: #e3d9c8;
  --color3: green;
  --color4: rgba(252, 76, 3, 0.5);
  --color-red: red;

}

.orange-button {
  color: var(--color0);
  border: 1px solid var(--color0);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orange-button:hover {
  background-color: var(--color0);
  color: var(--color1);
}

.green-button {
  color: green;
  border: 1px solid green;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.green-button:hover {
  background-color: green;
  color: var(--color1);
}

.red-button {
  color: red;
  border: 1px solid red;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.red-button:hover {
  background-color: red;
  color: white;
}

.small-button {
  margin: 1px;
  padding: 3px;
}

.user-actions .red-button {
  color: red;
  border: 1px solid red;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-actions .red-button:hover {
  background-color: red;
  color: white;
}

.loading {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color0);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Notifications */
.notifications-container {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}

.notifications-buttons {
  display: flex;
  flex-direction: row;
}

.notifications-buttons button {
  margin-left: 5px;
}

.new-email-button {
  display: flex;
  flex-direction: row;
}

.notifications-new-email-icon {
  position: relative;
  padding-right: 20px;
  overflow: visible;
}

.faBell-new-email {
  position: absolute;
  top: -8px;
  right: 12px;
  font-size: smaller;
}

.faBell-follow-request {
  margin-right: 10px;
}

/* End notifications */


/* Users messaging */

.users-messaging-container {
  max-width: 500px;
  margin: 20px auto;
  background-color: var(--color1);
  border-radius: 5px;
  padding-bottom: 5px;
}

.users-messaging-user {
  text-align: center;
  padding: 10px;
  font-size: x-large;
  font-weight: 900;
  color: var(--color0);
}

.users-messaging-send {
  padding: 10px;
  text-align: center;
}

.users-messaging-send input {
  margin-right: 10px;
}

.users-messaging-me {
  margin: 5px;
  display: flex;
  flex-direction: column;
  text-align: end;
}

.users-messaging-them {
  margin: 5px;
}

.users-messaging-message-date {
  text-align: center;
}

.users-messaging-message-content {
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
}

.message-me {
  margin-left: 30%;
  color: white;
  background-color: var(--color0);
  text-align: left;
}

.message-them {
  margin-right: 30%;
  color: black;
  background-color: grey;
  color: white;
}

/* End users messaging */

.large-picture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.maps,
.rides,
.allmaps,
.mapslist {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 0.5em;
}

.title {
  max-height: 5%;
  cursor: pointer;
}

.title {
  background-color: mediumaquamarine;
}

.maps {
  background-color: lightgoldenrodyellow;
}

.rides {
  background-color: lightgoldenrodyellow;
  flex-direction: column;
}

.ridesform {
  display: flex;
  flex-direction: column;
}

.allmaps {
  background-color: goldenrod;
}

.mapslist {
  background-color: lightgreen;
  flex-direction: column;
}

/* Delete last marker, delete all markers from map */

.deletebuttons {
  text-align: end;
  padding: 0em 1em 1em 1em;
  white-space: nowrap;
}

.leaflet-container {
  height: 50vh;
  padding: 0.7em;
  border-radius: 20px;
}

/* Hide the details of the route */
.leaflet-routing-alternatives-container {
  display: none;
}

.recbin {
  height: 1.5em;
  width: 1.5em;
  margin: 5px;
  display: inline-block;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  right: 0;
  top: 0;
}

.time-picker {
  display: flex;
  flex-direction: column;
  width: 150px;
}

.time-picker label {
  margin-bottom: 5px;
}

.time-picker input {
  width: 60px;
}

/* || MOBILE DEVICE LANDSCAPE  */
/* @media screen and (max-height: 320px) and (min-aspect-ratio: 7/4) {
  .leaflet-container {
    height: 50vh;
  }
} */