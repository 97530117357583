.maps-public-container {
    /* border: 1px solid var(--color0); */
    border-bottom: 1px dotted var(--color0);
    /* border-radius: 10px; */
    padding: 5px;
    /* margin: 5px; */
    color: var(--color0);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.maps-public-information {
  display: flex;
color: black;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.maps-public-information > :last-child {
/* background-color: red; */
}


/* .maps-public-information div {
  flex-basis: calc(25% - 30px); 
  max-width: calc(25% - 30px); 
} */

.maps-public-map-name {
  word-wrap: break-word;
}

/*
 .maps-public-information div {
  flex-grow: 1;
}

.maps-public-information div:nth-child(3n+1) {
  flex-basis: calc(33.33% - 30px); 
  max-width: calc(33.33% - 30px); 
}

.maps-public-information div:nth-child(3n+2),
.maps-public-information div:nth-child(3n+3) {
  flex-basis: calc(33.33% - 30px); 
  max-width: calc(33.33% - 30px); 
} */

.maps-public-remove-button {
    /* margin: auto; */
    max-width: 200px;
    /* margin-top: 10px; */
  
    color: var(--color-red);
    border: 1px solid var(--color-red);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .maps-public-remove-button:hover {
    background-color: var(--color-red);
    color: var(--color1);
  }

  .maps-public-add-button {
    /* margin: auto; */
    max-width: 200px;
    /* margin: 10px 0px 0px 5px; */
  
    color: var(--color0);
    border: 1px solid var(--color0);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .maps-public-add-button:hover {
    background-color: var(--color0);
    color: var(--color1);
  }


  @media (min-width: 800px) {
    .maps-public-container {
      margin: 5px 10% 0% 10%;
    }
  }
  