.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #e0e0e0; */
  /* border-radius: 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
  /* margin-bottom: 20px; */
  background-color: #f9f9f9;
  padding-bottom: 100px;
}

.user-profile-image-container {
  position: relative;
  margin: 2em 0em 2em 0em;

}

.user-profile-image-container:hover .fa-pen-to-square {
  cursor: pointer;
}

.user-profile-image-container:hover .user-profile-image {
  padding: 0px;
}

.user-profile-image-container .fa-pen-to-square {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 85%;
  transform: translate(-50%, -50%);
  color: var(--color1);
}


.user-profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid 5px var(--color0);
  padding: 2px;
  overflow: hidden;
  object-fit: cover;
  
}





.user-profile-image:hover {
  padding: 0px;
  cursor: pointer;
}

.hover-edit-image-buttons {
  font-size: x-large;
  color: white;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  /* Position at 50% from the top of the parent */
  left: 38%;
  /* Position at 50% from the left of the parent */
  cursor: pointer;
}

.file-upload-section {
  margin: 5% 0% 0% 0%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 2px solid var(--color0);
  padding: 20px;
  border-radius: 20px;
}


.user-profile-default-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  border-radius: 50%;
  border: solid 1px var(--color0);
  padding: 10px;
  min-width: 100px;
  min-height: 100px;
  max-height: 200px;
  max-width: 200px;
  margin-bottom: 30px;
  overflow: hidden;
  transition: padding 0.5s;
  position: relative;
  color: var(--color0);
}

.user-profile-default-icon:hover {
  cursor: pointer;
  /* border: solid 5px var(--color0); */
  color: var(--color1);
  background-color: var(--color0);
}

.user-profile-default-icon:hover .fa-plus {
  color: var(--color0);
  /* Change the color to var(--color0) when hovered */
}

.user-profile-default-icon .fa-image {
  font-size: 48px;
  /* Adjust the size as needed */
  position: absolute;
  /* Position the image icon absolutely */
  top: 50%;
  /* Position at vertical center */
  left: 50%;
  /* Position at horizontal center */
  transform: translate(-50%, -50%);
  /* Center the icon */
}

.user-profile-default-icon .fa-plus {
  font-size: 14px;
  /* Adjust the size as needed */
  position: absolute;
  /* Position the plus icon absolutely */
  top: 30px;
  /* Position at 10px from the top */
  right: 30px;
  /* Position at 10px from the right */
  color: white;
}

.user-profile-username-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 30px; */
}

.user-profile-username,
.user-profile-email,
.user-profile-permissions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.user-profile-username-container input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* width: 50%; */
  min-width: 300px;
  margin-bottom: 20px;
}

.user-profile-edit-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile-edit-button {
  /* margin-top: 10px; */
  color: var(--color0);
  border: 1px solid var(--color0);
  padding: 10px 20px;
  /* margin-left: 10px; */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-profile-edit-button:hover {
  background-color: var(--color0);
  color: var(--color1);
}

.user-profile-save-username-button {
  /* margin-top: 20px; */
  color: var(--color3);
  border: 1px solid var(--color3);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.user-profile-save-username-button:hover {
  background-color: var(--color3);
  color: var(--color1);
}

.user-profile-save-username-button[disabled] {
  opacity: 0.5;
  /* Example: reduces opacity to visually indicate disabled state */
  cursor: not-allowed;
  /* Example: changes cursor to not-allowed */
}

.delete-buttons-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile-delete-button-close {

  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;

  /* Center */

  display: flex;
  /* Center horizontally and vertically */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  width: 30px;
  height: 30px;
}

.user-profile-delete-button-close:hover {
  background-color: #5a6268;
}

.user-profile-delete-button {
  /* margin-top: 10px; */
  color: var(--color-red);
  border: 1px solid var(--color-red);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-profile-delete-button:hover {
  background-color: var(--color-red);
  color: var(--color1);
}