
.navsidebar {
  position: fixed;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
height: 100vh;
  background-color: var(--color0);
  color: var(--color1);
  overflow-y: auto; /* Scroll */
  padding-bottom: 75px;
}

.navsidebar > *:not(:first-child) {
  margin-top: 40px;
  /* Space between children */
}

.navsidebar-dropdown-wrapper {
  cursor: pointer;
  /* padding-left: 25px; */
  /* text-align: center; */
}

.navsidebar-dropdown-wrapper:hover {
  color: var(--color1);
}

.navsidebar-title {
  /* margin-bottom: 10px; */
  /* display: flex; */
  padding: 10px 0px 10px 0px;
}

.navsidebar-title:hover {
  color: var(--color0);
  background-color: var(--color1);

  
}


.navsidebar-dropdown {

  display: flex;
  flex-direction: column;
  border-top: none;
  z-index: 1001;
  white-space: nowrap;

}

.navsidebar-dropdown button {
display: flex;
/* justify-content: center; */
  color: var(--color1);
  border: none;
  background: none;
  cursor: pointer;
  padding: 15px;
}

.navsidebar-dropdown-wrapper-text {
 margin: 15px 0px 15px 15px;
 font-weight: 900;
}

.navsidebar-dropdown-wrapper-text:hover {
  margin: 15px 0px 15px 15px;
 }

.down-arrow {
  /* background-color: white; */
  margin-bottom: 10px;
}




.navsidebar-dropdown button:first-child {
  /* padding-top: 20px; */
}


.navsidebar-dropdown button:hover {
  color: var(--color0);
  background-color: var(--color1);
  font-weight: 700;
}

.navsidebar-my-account {
  /* padding-left: 15%; */
}

.navsidebar-my-account:hover .navsidebar-profile-picture {
  /* CSS properties to apply when hovering over .navsidebar-my-account */
  /* For example: */
  max-width: 60px;
  max-height: 60px;
  border: 2px solid var(--color1);

}


.navsidebar-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid var(--color1);
  padding: 2px;
overflow: hidden;
object-fit: cover;
}


.navsidebar-profile-default-icon {
  /* padding-top: 12px; */
}

.navsidebar-logout-button {
  font-size: 20px;
}

/* Styles for screens up to 425 pixels */
@media (max-width: 425px) {

  .navsidebar {
    
  }

    .navsidebar-dropdown-wrapper,
    .navsidebar-dropdown-wrapper button {
      font-size: 16px;
    }
  }
  
  /* Styles for screens between 425 and 768 pixels */
  @media (min-width: 426px) and (max-width: 850px) {
  
    .navsidebar {
      
    }

    .navsidebar-dropdown-wrapper,
    .navsidebar-dropdown-wrapper button {
      font-size: 18px;
    }
  }
  
  /* Styles for screens larger than 768 pixels */
  @media (min-width: 851px) {
  
    .navsidebar {
      display: none
    }

  
  }

  /* Styles for screens up to 425 pixels */
@media (max-width: 425px) {

  .navsidebar {

    min-width: 50%;

  }
  
  }

  /* Styles for screens between 425 and 650 pixels */
@media (min-width: 426px) and (max-width: 650px) {

  .navsidebar {

    min-width: 40%;

  }
}


  @media (min-width: 651px)  {

    .navsidebar {
  
      min-width: 30%;
  
    }
  }