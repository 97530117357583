.create-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5%;
  background-color: var(--color1);
  border-radius: 2%;
  margin-bottom: 0%;
  /* padding-bottom: 0%; */
  color: var(--color0);
  font-weight: 900;
max-width: 600px;
}

.create-title {
font-size: 25px;
display: flex;
justify-content: center;
}

.create-label-input {
  display: flex;
  flex-direction: row;
}

.create-label-input > label {
 width: 70%;
}

.create-label-input > input {
  width: 30%;
}


.container-list {
  margin-top: 20px;
}

.container-form {
  display: flex;
  flex-direction: column;
  /* width: 400px; */
}

.container-form label {
  margin-top: 10px;
  /* white-space: nowrap; */
  
}

.container-form input,
.cointainer-form input[type="text"],
.cointainer-form input[type="password"],
.container-form select {
  margin-top: 5px !important;;
  padding: 8px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.container-form input[type="date"] {
  /* Adjust appearance for date input if needed */
}

.create-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.preview-map {
  margin-top: 20px;
}

/* Time picker component */

.create-time-label-input {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
}

.time-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: red; */
}

.time-picker-hours, .time-picker-minutes {
  margin-left: 17px;
}

.time-picker-hours,
.time-picker-minutes {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add some space between the hours and minutes */
  /* background-color: yellow; */
}

.time-picker label {
  margin: 0 5px;
  /* background-color: pink; */
}

.time-picker input[type="number"] {
  width: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* background-color: blue; */
}

p {
  display: flex;
  justify-content: center;
}


@media (max-width: 450px) {

  .create-container {
    color: var(--color0);
    font-weight: 900;
    margin: 0 auto; 
    margin-bottom: 0%;
  }

  .create-label-input {
    flex-direction: column;
  }

  .create-label-input > label,
  .create-label-input > input {
    width: 100%;
  }
}

/* Create map css */


.create-map-container {
  max-width: 600px;
  color: var(--color0);
  font-weight: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% auto;
  padding: 5%;
  background-color: var(--color1);
  border-radius: 2%;
}

.create-map-container form {
  width: 100%;
}

.create-map-container div {
  margin-bottom: 10px;
}

.create-map-container input,
.create-map-container select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.create-map-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-map-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.create-map-label {
  font-weight: bold;
  color: var(--color0);
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.create-map-input{
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}


.error {
  color: red;
  margin-top: 10px;
}

@media (max-width: 450px) {
  .create-map-container {
    margin: 5% auto;
  }
}
