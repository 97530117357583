
/* Hamburger menu CSS */
.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 10002;
  position: fixed; /* Position the menu absolutely */
  top: 3%; /* Adjust top position as needed */
  left: 20px; /* Adjust right position as needed */

}

.line {
  width: 100%;
  height: 3px;
  background-color: var(--color0);
}

.line-white {
  width: 100%;
  height: 3px;
  background-color: var(--color1);
}

/* Animation for hamburger menu */
.line:nth-child(2) {
  transition: transform 0.2s ease-in-out;
}

/* Animation for toggling hamburger menu */
.hamburger-menu.open .line:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.hamburger-menu.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .line:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}
@media (min-width: 851px) {
  
  .hamburger-menu {
    display: none;
  }

}