/* Footer stays at the bottom when  scrolling is needed and when it's not */

.footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 0;
}

@media screen and (min-height: 600px) {


  .footer {
    position: static;
    margin-top: auto;
  }
}

@media screen and (max-height: 500px) {
  .footer {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    
  }
}