
/* Navbar and dropdown */

@font-face {
  font-family: "Rams W01 Black", Arial, sans-serif;
  src: url('../fonts/Rams\ W01\ Black.woff');
}

.navbar {
  margin-left: 275px;
  /* Maintain left margin */
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Rams W01 Black', sans-serif;
  z-index: 500;
}

.navbar>* {
  margin-right: 50px;
  /* Space between children */
}

.navbar> :last-child {
  margin-right: 0;
  /* No margin for the last child */
}


.navbar-public {
  display: flex;
  align-items: flex-start;
}

/* .admin-navbar {
  margin-left: auto;
} */

.dropdown-wrapper {
  position: relative;
  cursor: pointer;
  text-align: left;
  /* padding-left: 5px; */
}

.dropdown-wrapper:hover {
  color: #fc4c02;
  font-weight: 700;
}

.navbar-title {
  /* margin-bottom: 10px; */
  /* display: flex; */
  padding: 10px 0px 10px 10px;
}

.navbar-title:hover {
  color: var(--color0);
  background-color: #ddd;
  font-weight: 700;
}



.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 10 px 10px 10px 0px;
  /* Hide next */
  background-color: #f9f9f9; 
  border-top: none;
  z-index: 90;
  white-space: nowrap;
}

.dropdown button {
  padding: 10px 10px 10px 10px;
  text-align: left;
  border: none;
  cursor: pointer;
}

.dropdown-button-text {

}

.dropdown button:first-child {
  /* margin-top: 10px; */
}

.dropdown button:hover {
  background-color: #ddd;
  color: #fc4c02;
  font-weight: 700;
}


.navsidebar-dropdown-wrapper-text {
  margin: 15px 0px 15px 15px;
  font-weight: 900;
 }
 
 .navsidebar-dropdown-wrapper-text:hover {
   margin: 15px 0px 15px 15px;
  }


.down-arrow {
  padding-left: 10px;
}

/* Styles for screens up to 425 pixels */
@media (max-width: 425px) {

.navbar {
  display: none
}

  .dropdown-wrapper,
  .dropdown-wrapper button {
    font-size: 12px;
  }
}

/* Styles for screens between 425 and 768 pixels */
@media (min-width: 426px) and (max-width: 850px) {

  .navbar {
    display: none
  }

  .dropdown-wrapper,
  .dropdown-wrapper button {
    font-size: 14px;
  }
}

/* Styles for screens larger than 768 pixels */
@media (min-width: 851px) {

  .dropdown-wrapper,
  .dropdown-wrapper button {
    font-size: 16px;
  }

}


.my-account {
  cursor: pointer;
  position: absolute;
  margin-top: 5px;
  right: 40px;
}

/* //Navbar and dropwon */


/* For the My account button on the navbar */

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid white;
  padding: 2px;
overflow: hidden;
}



  




.navbar img {
  max-width: 50px; 
  max-height: 50px; 
  object-fit: cover; 
}


.profile-default-icon {
  /* padding-top: 12px; */
}

.dropdown-right {
  position: absolute;
  top: 100%;
  right: 0;
  /* Adjusted to position on the right side */
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  /* border: 1px solid #ddd; */
  border-top: none;
  z-index: 90;
  white-space: nowrap;
  /* padding: 0px 10px 0px 40px;  */
}

.dropdown-right button {
  padding: 10px 10px 10px 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown-right button:first-child {
  /* padding-top: 10px; */
}

.dropdown-right button:hover {
  background-color: #ddd;
  color: #fc4c02;
  font-weight: 700;
}

/* End for the My account button on the navbar */