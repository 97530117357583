/* UserEditPassword.css */

.edit-password-container {
  max-width: 300px;
  /* background-color: red; */
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
}

.edit-password-input-div {
/* background-color: green; */
  margin: 5px 0px 5px 0px;
  padding: 2px;
  display: flex;
  flex-direction: column;
}

.edit-password-input {
margin-top: 10px;
}

/* Error message */
.errmsg {
  color: red;
  margin-top: 5px;
}

/* Validation icons */
.valid {
  color: green;
}

.invalid {
  color: red;
}

.hide {
  display: none;
}

/* Instructions for password input */
.instructions {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.fa-info {
  color: blue
}

/* Button */
.btn {
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.button-black {
  background-color: var(--color-black);
  color: var(--color-white);
  border: none;
}

/* Button hover effect */
.btn:hover, .button-black:hover {
  opacity: 0.8;
}

/* CSS for .offscreen class */
.offscreen {
  position: absolute;
  left: -9999px;
}

.user-profile-edit-password-button {

  margin-top: 10px;
  color: var(--color0);
  border: 1px solid var(--color0);
  padding: 10px 20px;
  /* margin-left: 10px; */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-profile-edit-password-button[disabled] {
  opacity: 0.5; /* Example: reduces opacity to visually indicate disabled state */
  cursor: not-allowed; /* Example: changes cursor to not-allowed */
}