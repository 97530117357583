

.filter-container {
 

  background-color: var(--color1);
  padding: 10px;
  border-radius: 5px;


  
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
   gap: 5px; 

   margin: 10px auto;
   max-width: 700px;

}

.filter-range {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.filter-label {
  font-weight: bold;
}

.filter-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-label,
.filter-input {
  width: 33%; 
}

/* .filter-span {
  margin: 0 5px;
} */


.filter-button {
  max-width: 150px;
  padding: 5px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background-color: white;
  color: var(--color0); 
 
}

.filter-button:hover {
  /* background-color: var(--color4); */
 font-weight: 900;
}

/* Circle grey */

/*
.filter-button-close {

  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;


/* Center */

/*

display: flex; 
justify-content: center; 
align-items: center;
width: 30px; 
height: 30px;
}

*/

/* .filter-button-close:hover {
  background-color: #5a6268;
} */


/* Simple red */
.filter-button-close {

 /* background-color: white; */
  color: var(--color-red);
  border: none;
  /* padding: 10px; */
  /* border-radius: 50%; */
  cursor: pointer;
  transition: font-weight 0.3s ease;


/* Center */

display: flex; /* Center horizontally and vertically */
justify-content: center; /* Center horizontally */
align-items: center; /* Center vertically */
/* width: 30px; 
height: 30px; */
padding: 3px;
}

 .filter-button-close:hover {
  /* background-color: red; */
  /* color: white;
  border-radius: 5px; */
  /* padding: 1px; */
 font-weight: 900;
}




@media screen and (max-width: 500px) {

.filter-container {
  margin: 5px;
}

.filter-container-map {

}

  .filter-range {
display: flex;
/* flex-direction: column; */
  }

  .filter-label,
.filter-input {
  width: 75%; 
}

.hide-small {
  display: none;
}

.filter-button-close {
  /* margin-top: 10px; */
}

.filter-input[type="date"] {
  font-size:  12px;
}


}


@media screen and (min-width: 501px) and (max-width: 1300px) {

  .filter-container {
    /* flex-direction: row; */
 
  }

  .filter-container-map {
    margin: 5px auto;
  }

  .hide-small {
    display: none;
  }

  .filter-label {
  width: 33%
}


}

@media screen and (min-width: 1301px) {
  .filter-container {
    /* margin: 5% 15% 5% 15%; */
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
     gap: 10px; 
     /* This two combined to have a max width but still centered */
     /* margin: 10px; */
     min-width: 100%;
     max-width: 100%
  }


.filter-container-map {
  min-width: 60%;
     max-width: 60%
}

.filter-container-map div:first-child {
max-width: 15%;
}

.filter-container-map .filter-input {
width: 70%;
}



  .filter-input[type="date"] {
    width: 150px; /* Adjust this value as needed */
  }

  .filter-input[type="number"] {
    width: 100px; /* Adjust this value as needed */
  }

  .hide-big {
    display: none;
  }

  .filter-label {
  width: auto; 
  margin-right: 5px;
}



}