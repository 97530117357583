.users-all-container {
    /* background-color: var(--color1); */
    margin: 0 auto auto auto;

    /* max-width: 700px; */
    /* min-width: 250px; */
    padding: 10px;

    border-radius: 10px;
    max-width: 700px;
    padding-bottom: 100px;
}

.users-title {
    display: flex;
    justify-content: center;
    padding: 20px;
    font-weight: 900;
    font-size: x-large;
    color: var(--color0);
}

.users-all-user {
    margin-top: 10px;
    display: flex;
    align-items: center;
    min-height: 50px;
}

.users-admin-user {
    display: flex;
    align-items: center;
    min-height: 50px;
    justify-content: space-between;
}

.users-all-picture-container {
    width: 30px;
    /* Adjust container size as needed */
    height: 30px;
    /* Adjust container size as needed */
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.users-all-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px var(--color0);
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.users-messaging-picture-container {

 padding: 10px;
 display: flex;
justify-content: center;
}

.users-messaging-picture {
width: 50px;
height: 50px;
border-radius: 50px;
border: solid 1px var(--color0);
padding: 2px;
object-fit: cover;
object-position: center;
cursor: pointer;
}

.users-all-picture-large {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: solid 1px var(--color0);
    object-fit: cover;
    object-position: center;
}


.user-details {
    flex: 1;
    margin-left: 10px;
    /* Adjust as needed for spacing */
}

.user-actions {
    display: flex;
    gap: 5px;
    /* Adjust as needed for spacing between buttons */
    /* margin-left: 100px; */
    flex-wrap: wrap;


}

.user-actions button {
    /* min-width: 80px;  */
}

.users-pending-request-user-wrapper {
    margin-right: 10px;
}

.users-pending-request-made {
    margin-bottom: 10px;
}

.users-pending-user {
    display: flex;
    flex-direction: row;
}

.muted-users-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Admin */

.users-inactive {
    background-color: red;
    color: white;
    padding: 5px;
}

.users-admin-picture {
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px 0px 5px;
    padding: 10px;
}

.users-admin-name {
    margin: 5px;
}

.user-admin-details {
    /* background-color: red; */
    display: flex;
}

.user-admin-admin {
    display: flex;
    /* background-color: green; */
    margin: 5px;
    align-items: center;
    gap: 10px
}

.users-admin-inner-container {
    flex-direction: column;
}

/* End Admin */

@media (min-width: 426px) {
    .users-all-name {
        padding-right: 20px;
    }

    .users-pending-request-made {
        margin-bottom: 0px;
        margin-left: 10px;
        font-style: italic;
    }
}

@media (max-width: 426px) {
    .users-all-container {
        padding: 5px;
        min-width: none;
        margin: 5px
    }

    .users-all-user {
        padding: 5px 0px;
        flex-wrap: wrap;
        border-bottom: 1px dotted var(--color0);
    }

    .user-details {
        margin-left: 10px;
        /* Remove left margin for smaller screens */

        width: 100%;
        /* Make details take full width */
    }

    .user-actions {
        padding: 10px 0px;
        justify-content: flex-end;
        /* Align buttons to the right */
    }


}


@media (max-width:600px) {
    .users-admin-user {
        padding: 5px 0px;
        flex-direction: column;
        border-bottom: 1px dotted var(--color0);
    }

    .users-all-picture-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .user-admin-details {
        flex-direction: column;
        text-align: center;
    }

}