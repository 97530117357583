.rides-public-filter-ride {

  display: flex;
  padding: 5px;
  border: none;
  /* border-radius: 5px; */
  font-size: 18px;
  cursor: pointer;
  background-color: white;
  color: var(--color0);
  max-width: 25px;
  margin-bottom: 10px;


  width: 100%;
max-width: 600px; 
text-align: center; 
margin: 0 auto; 

}

.rides-public-filter-ride:hover {
  /* background-color: var(--color4); */
  color: var(--color4);
}

.rides-public-container {
  /* border: 1px solid red; */
  width: 100%;
  padding: 5px;

  /* margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */

  padding-bottom: 100px;
}


.rides-public-mapped {
  /* border: 1px solid green; */

}


.rides-public-ride {
  padding-bottom: 10px;
  border-bottom: 1px dotted var(--color0);
padding-top: 10px;


padding: 10px 0;
border-bottom: 1px dotted var(--color0);
width: 100%;
max-width: 600px; 
text-align: center; 
margin: 0 auto; 


}

.rides-public-ride div {
  text-align: justify;
}

.rides-public-ride:first-child {
  padding-top: 0px;
}

.rides-public-ride-name {
  margin-top: 10px;
}

.rides-public-ride-top-buttons {
margin: 5px px 5px 0px;

}

.rides-public-ride-top-buttons button:first-child {
margin-right: 10px;
  
  }

.cross-map {
  position: absolute;
  width: 250%;
  height: 2px;
  background-color: var(--color0);
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  left: -60%;
}

.map-crossed-out {
  position: relative;
  display: inline-block;
}


.map-crossed-out .faMapLocation {
  position: relative;
  z-index: 1;
}

.map-crossed-out .cross-map {
  background-color: var(--color0);
  transition: background-color 0.3s;
}

.orange-button:hover .map-crossed-out .cross-map {
  background-color: white;
  /* Change to white on hover */
}

.rides-public-join-buttons {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  /* justify-content: center; */
  /* border: 1px solid purple; */
  flex-wrap: wrap;
}

.rides-public-remove-button {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  /* justify-content: center; */
}


.rides-public-joined-information {
  background-color: var(--color1);
  border-radius: 5px;
  margin: 5px 0px 5px 0px;
  font-size: smaller;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.rides-public-joined-information div {
margin: 2px 0px 2px 0px;
}

.rides-public-joined-information div:last-child {
  font-style: italic;
}

.rides-public-joined-users-list {
  display: flex;
  flex-direction: column;
}

.pointer:hover {
  cursor: pointer;
}

.ride-conversation-container {
  /* border: 3px solid green */
  background-color: var(--color1);
  border-radius: 5px;
}

.inactive-buttons {
  padding-top: 10px;
display: flex;
align-items: center;

}

.inactive-r {
color: white;
background-color: red;
font-style: italic;
margin-right: 5px;
padding: 3px;
border-radius: 5px;
}

.refresh-messages-and-info {
  display: flex;
  justify-content: flex-end; /* Align buttons to the end of the container */
  gap: 10px; /* Adds spacing between buttons */
}

.info-button {
  margin: 10px 10px 10px 0px; 
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
max-height: 10px;
max-width: 10px;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.info-message {
  padding: 10px;
  font-style: italic;
  font-size: smaller;
  background-color: blue;
  color: white;
  margin: 5px;
  border-radius: 5px;
}

.info-button:hover {
  background-color: navy;
}



@media (min-width:500px) {
  .ride-conversation-container{

  }
}

@media (min-width:900px) {
  .ride-conversation-container{

  }
}

.flagged-inappropiate-message {
  /* background-color: red; */
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  z-index: 9999; /* ensure modal is on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-container button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
  outline: none;
}