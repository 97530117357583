.add-ride-message-container {
display: flex;
flex-direction: column;
justify-content: center;
margin-top: 10px;
padding: 10px 50px 0px 50px;
}

.add-ride-message-container textarea {
  padding: 10px;
}

.add-ride-message-input {
width: 100%;
min-height: 30px;
}

.add-ride-message-container button {
  max-width: 100px;
}

.mapped-messages-container {
  margin: 5px 0px 5px 0px;
  /* border: 1px solid red; */
  border-radius: 5px;
  background-color: var(--color4);
  display: flex;
  flex-direction: column;
  
}

.my-comment {
  margin-left: 20%;

}

.their-comment {
  margin-right: 20%;
  background-color: grey;
  font-style: italic;
}


.mapped-messages-container p {
  justify-content: left;
  /* margin: 2px 0px 2px 0px; */
}

.mapped-messages-name-and-message {
  margin: 2px;
  background-color: var(--color1);
  border-radius: 5px;
  padding: 5px;

  
}

.mapped-messages-username {
  font-weight: 600;
 
}

.deleted-message {
  color: red;
  font-style: italic;
  font-size: smaller;
}

.deleted-message-margin {
/* margin-left: 50% */
}

.mapped-messages-date {
  font-style: italic;
  font-size: smaller;
  margin-left: 2px;
  padding-left: 5px;
}

.mapped-messages-reported {
  font-size: smaller;
  font-style: italic;
  /* padding-left: 5px; */
}

.mapped-messages-users-buttons {
  display: flex;
}

.mapped-messages-admin-buttons {
  display: flex;
  padding-left: 5px;
  align-items: center;
}

.delete-ride-message-container {
  /* background-color: green; */
}

.report-innapropiate-message {

}

.flagged-inappropiate-message {
  margin: 5px 0px 5px 0px;
  color: red;
  font-style: italic;
  font-size: smaller;
}